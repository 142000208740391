import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom'
import loadable from '@loadable/component'
import MainLayout from '../layout/MainLayout'
import PublicLayout from '../layout/PublicLayout'
import NotFound from '../views/NotFound'
import Login from '../views/Login'
import Register from '../views/Register'
import ForgotPassword from '../views/ForgotPassword'
import ViewLoading from '../views/ViewLoading'
import { AsyncPageProps, RoutePath } from './types'

const AsyncPage = loadable<AsyncPageProps>(
  (props) => import(`../views/${props.page}/index.tsx`),
  {
    fallback: <ViewLoading />,
    cacheKey: (props) => props.page,
  }
)

const privateRoutes: RouteObject = {
  path: RoutePath.Base,
  element: <MainLayout />,
  children: [
    {
      path: RoutePath.Base,
      element: <Navigate to={RoutePath.Home} replace />,
    },
    {
      path: RoutePath.Home,
      element: <AsyncPage page="Dashboard" />,
    },
    {
      path: RoutePath.Stores,
      element: <AsyncPage page="Stores" />,
    },
    {
      path: RoutePath.StoresCreate,
      element: <AsyncPage page="MutateStore" />,
    },
    {
      path: RoutePath.StoresEdit,
      element: <AsyncPage page="MutateStore" />,
    },
    {
      path: RoutePath.StoresAnalytics,
      element: <AsyncPage page="StoreAnalytics" />,
    },
    {
      path: RoutePath.StoresAttendance,
      element: <AsyncPage page="StoreAttendance" />,
    },
    {
      path: RoutePath.StoresCatalog,
      element: <AsyncPage page="StoreCatalog" />,
    },
    {
      path: RoutePath.StoresDevices,
      element: <AsyncPage page="StoreDevices" />,
    },
    {
      path: RoutePath.StoresSettings,
      element: <AsyncPage page="StoreSettings" />,
    },
    {
      path: RoutePath.StoresTransactions,
      element: <AsyncPage page="StoreTransactions" />,
    },
    {
      path: RoutePath.Products,
      element: <AsyncPage page="Products" />,
    },
    {
      path: RoutePath.ProductsCreate,
      element: <AsyncPage page="MutateProduct" />,
    },
    {
      path: RoutePath.ProductsEdit,
      element: <AsyncPage page="MutateProduct" />,
    },
    {
      path: RoutePath.Variants,
      element: <AsyncPage page="Products" />,
    },
    {
      path: RoutePath.VariantsCreate,
      element: <AsyncPage page="MutateVariant" />,
    },
    {
      path: RoutePath.VariantsEdit,
      element: <AsyncPage page="MutateVariant" />,
    },
    {
      path: RoutePath.Customers,
      element: <AsyncPage page="Customers" />,
    },
    {
      path: RoutePath.CustomersCreate,
      element: <AsyncPage page="MutateCustomer" />,
    },
    {
      path: RoutePath.CustomersEdit,
      element: <AsyncPage page="MutateCustomer" />,
    },
    {
      path: RoutePath.Campaigns,
      element: <AsyncPage page="Campaigns" />,
    },
    {
      path: RoutePath.CampaignsCreate,
      element: <AsyncPage page="MutateCampaign" />,
    },
    {
      path: RoutePath.CampaignsEdit,
      element: <AsyncPage page="MutateCampaign" />,
    },
    {
      path: RoutePath.Loyalty,
      element: <AsyncPage page="Loyalty" />,
    },
    {
      path: RoutePath.LoyaltyCreate,
      element: <AsyncPage page="MutateLoyalty" />,
    },
    {
      path: RoutePath.LoyaltyEdit,
      element: <AsyncPage page="MutateLoyalty" />,
    },
    {
      path: RoutePath.Organization,
      element: <AsyncPage page="Organization" />,
    },
    {
      path: RoutePath.OrganizationSection,
      element: <AsyncPage page="Organization" />,
    },
    {
      path: RoutePath.OrganizationPeopleCreate,
      element: <AsyncPage page="MutateUser" />,
    },
    {
      path: RoutePath.OrganizationPeopleEdit,
      element: <AsyncPage page="MutateUser" />,
    },
    {
      path: RoutePath.Settings,
      element: <AsyncPage page="Settings" />,
    },
    {
      path: RoutePath.SettingsSection,
      element: <AsyncPage page="Settings" />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ],
}

const publicRoutes: RouteObject = {
  path: RoutePath.Base,
  element: <PublicLayout />,
  children: [
    {
      path: RoutePath.Login,
      element: <Login />,
    },
    {
      path: RoutePath.Register,
      element: <Register />,
    },
    {
      path: RoutePath.ForgotPassword,
      element: <ForgotPassword />,
    },
  ],
}

const router = createBrowserRouter([privateRoutes, publicRoutes])

function Routes() {
  return <RouterProvider router={router} />
}

export default Routes
