import axios from 'axios'
import { createHttpClient } from '../http'
import { ApiUser } from '../users/types'
import { AUTH_BASE_URL } from './config'
import {
  getItemFromStorage,
  removeItemFromStorage,
  setItemInStorage,
} from '../storage'
import { StorageKey } from '../../config/types'
import { setApiClientBaseRoute, setApiClientToken } from '../api'
import { setSentryOrg, setSentryUser } from '../sentry'
import { IS_HTTP_WITH_CREDENTIALS } from '../../config/constants'
import {
  ForgotPasswordDto,
  ForgotPasswordResponse,
  LoginDto,
  LoginResponseDto,
  RegisterDto,
  UpdateProfileDto,
} from './types'

const authClient = axios.create({
  baseURL: AUTH_BASE_URL,
  withCredentials: IS_HTTP_WITH_CREDENTIALS,
})

const authHttpClient = createHttpClient(authClient)

function setAuthClientToken(token: string) {
  const initialHeaders = authClient.defaults.headers.common
  authClient.defaults.headers.common = {
    ...initialHeaders,
    Authorization: `Bearer ${token}`,
  }
}

function setClientToken(token: string) {
  if (IS_HTTP_WITH_CREDENTIALS) {
    return
  }

  setAuthClientToken(token)
  setApiClientToken(token, logoutUser)
  setItemInStorage(StorageKey.JwtAuthToken, token)
}

export function login(params: LoginDto) {
  const path = '/auth/login'
  return authHttpClient
    .post<LoginResponseDto, LoginDto>(path, params)
    .then((res) => {
      const token = res.data.authToken
      setClientToken(token)
      return token
    })
}

export function register(params: RegisterDto) {
  const path = '/auth/register'
  return authHttpClient
    .post(path, {
      ...params,
      org: {
        name: params.org,
      },
    })
    .then((res) => res.data)
}

export function forgotPassword(params: ForgotPasswordDto) {
  const path = '/auth/forgot-password'
  return authHttpClient
    .post<ForgotPasswordResponse, ForgotPasswordDto>(path, params)
    .then((res) => {
      const success = res.data.success
      if (!success) {
        throw new Error(res.data.message)
      }

      return true
    })
}

export function findUserProfile() {
  const path = '/auth/profile'
  return authHttpClient.get<ApiUser>(path).then((res) => res.data)
}

export function updateProfile(params: UpdateProfileDto) {
  const path = '/auth/profile'
  return authHttpClient
    .put<ApiUser, UpdateProfileDto>(path, params)
    .then((res) => res.data)
}

export function updateProfilePassword(
  oldPassword: string,
  newPassword: string
) {
  return updateProfile({
    oldPassword,
    password: newPassword,
  })
}

export async function loginUser(params: LoginDto) {
  const authToken = await login({
    email: params.email,
    password: params.password,
  })
  setClientToken(authToken)
  return authToken
}

export function logoutUser() {
  const path = '/auth/logout'
  removeItemFromStorage(StorageKey.JwtAuthToken)
  return authHttpClient.post(path)
}

export async function authenticateUser() {
  // check if there is an existing JWT token in local storage
  const storedToken = getItemFromStorage(StorageKey.JwtAuthToken)
  if (storedToken && !IS_HTTP_WITH_CREDENTIALS) {
    // if there is a stored token, we try to fetch the user's profile
    setClientToken(storedToken)
  }

  try {
    const user = await findUserProfile()
    setApiClientBaseRoute(user.orgId)
    setSentryUser(user.id)
    setSentryOrg(user.orgId)
    return user
  } catch (e) {
    // we "logout" the user here
    logoutUser()
    return null
  }
}
