import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { parseQueryString, stringifyQuery } from '.'
import { RoutePath } from '../../routes/types'
import { NavigateWithStateParams } from './types'

export function useSearchParams<T>(): Partial<T> {
  const { search } = useLocation()
  const searchParams = useMemo(() => {
    return parseQueryString(search)
  }, [search])
  return searchParams as Partial<T>
}

export function useViewParams<T>(): Partial<T> {
  const params = useParams()
  const viewParams = useMemo(() => {
    return (params || {}) as Partial<T>
  }, [params])
  return viewParams
}

export function useQueryNavigation<T>(): [
  Partial<T>,
  (params: Partial<T>) => void
] {
  const query = useSearchParams<T>()
  const navigate = useNavigate()

  const modifyQuery = useCallback(
    (params: Partial<T>) => {
      const currentQuery = {
        ...query,
        ...params,
      }
      const qstring = stringifyQuery(currentQuery)
      navigate(qstring)
    },
    [query, navigate]
  )

  return [query, modifyQuery]
}

export function useLoginRedirectPath() {
  const { pathname, search } = useLocation()
  const path = useMemo(() => {
    const query = stringifyQuery({
      redirect: pathname + search,
    })
    return `${RoutePath.Login}${query}`
  }, [pathname, search])
  return path
}

export function useLocationState<T>(): [
  Partial<T>,
  (modified: Partial<T>) => void
] {
  const { state } = useLocation()
  const navigate = useNavigate()

  const currentState = useMemo(() => {
    return (state ?? {}) as Partial<T>
  }, [state])

  const modifyState = useCallback(
    (modified: Partial<T>) => {
      navigate('', {
        state: {
          ...currentState,
          ...modified,
        },
      })
    },
    [navigate, currentState]
  )

  return [currentState, modifyState]
}

export function useNavigateWithState<T>() {
  const navigate = useNavigate()

  const navigateWithState = useCallback(
    (params: NavigateWithStateParams<T>) => {
      const { route, state, replace } = params
      if (typeof route === 'number') {
        return navigate(route)
      }

      return navigate(route, {
        replace: Boolean(replace),
        state,
      })
    },
    [navigate]
  )

  return [navigateWithState]
}
