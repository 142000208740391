import * as Sentry from '@sentry/react'
import { ErrorBoundaryProps } from './types'
import InternalError from '../InternalError'

function ErrorBoundary(props: ErrorBoundaryProps) {
  const { children } = props

  return (
    <Sentry.ErrorBoundary fallback={<InternalError />}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
