import { LinearProgress, Stack } from '@mui/material'
import { FastokoLogo } from '@tokoku-universe/react-core/components'
import MotionViewContainer from '../../components/MotionViewContainer'

function ViewLoading() {
  return (
    <MotionViewContainer
      maxWidth="xs"
      disableGutters
      sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <Stack
        width="100%"
        height="95%"
        gap={2}
        justifyContent="center"
        alignItems="center"
      >
        <FastokoLogo width={40} height={40} />
        <Stack width={80} mr={1.5}>
          <LinearProgress color="secondary" variant="indeterminate" />
        </Stack>
      </Stack>
    </MotionViewContainer>
  )
}

export default ViewLoading
