import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack'
import { SnackbarProviderProps } from './types'

const AUTO_HIDE_DURATION = 5000 // 5 seconds
const MAX_SNACK_COUNT = 3

function SnackbarProvider(props: SnackbarProviderProps) {
  const { children } = props

  return (
    <NotistackSnackbarProvider
      autoHideDuration={AUTO_HIDE_DURATION}
      maxSnack={MAX_SNACK_COUNT}
    >
      {children}
    </NotistackSnackbarProvider>
  )
}

export default SnackbarProvider
