import * as Sentry from '@sentry/react'

const appEnv = import.meta.env.VITE_APP_ENV
const sentryEnabledEnvironments: typeof appEnv[] = [
  'integration',
  'staging',
  'production',
]
const isProduction = appEnv === 'production'

const isSentryEnabled = sentryEnabledEnvironments.includes(appEnv)

export function initSentry() {
  return Sentry.init({
    enabled: isSentryEnabled,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tracesSampleRate: isProduction ? 0.1 : 1.0,
    replaysSessionSampleRate: isProduction ? 0.1 : 1.0,
    replaysOnErrorSampleRate: isProduction ? 0.1 : 1.0,
    environment: appEnv || 'development',
  })
}

export function setSentryUser(id: string) {
  return Sentry.configureScope((scope) => {
    scope.setUser({ id })
  })
}

export function setSentryOrg(id: string) {
  return Sentry.configureScope((scope) => {
    scope.setTag('org', id)
  })
}
