import { StorageKey } from '../../config/types'
import { StorageStrategy } from './types'

function getTransportFromStrategy(strategy: StorageStrategy = 'local') {
  return strategy === 'local' ? localStorage : sessionStorage
}

export function getItemFromStorage(
  key: StorageKey,
  strategy: StorageStrategy = 'local'
) {
  const transport = getTransportFromStrategy(strategy)
  return transport.getItem(key)
}

export function removeItemFromStorage(
  key: StorageKey,
  strategy: StorageStrategy = 'local'
) {
  const transport = getTransportFromStrategy(strategy)
  return transport.removeItem(key)
}

export function setItemInStorage(
  key: StorageKey,
  value: string | Record<string, unknown>,
  strategy: StorageStrategy = 'local'
) {
  const transport = getTransportFromStrategy(strategy)

  if (typeof value === 'string') {
    return transport.setItem(key, value)
  }

  try {
    const stringifiedValue = JSON.stringify(value)
    return transport.setItem(key, stringifiedValue)
  } catch (e) {
    // ignore
  }
}
