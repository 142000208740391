import * as Yup from 'yup'
import isStrongPassword from 'validator/es/lib/isStrongPassword'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import { mobilePhoneLocaleByCountry } from '../../config/constants'

export function isValidPhoneNumber(phone: string) {
  return isMobilePhone(phone, Object.values(mobilePhoneLocaleByCountry), {
    strictMode: true,
  })
}

export function isValidPassword(password: string) {
  return isStrongPassword(password, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  })
}

export const passwordValidationSchema = Yup.string().test({
  test: (pwd) => (pwd ? isValidPassword(pwd) : false),
})

export const phoneNumberValidationSchema = Yup.string().test({
  test: (phone) => (phone ? isValidPhoneNumber(phone) : false),
})

export const locationValidationSchema = Yup.object({
  lat: Yup.number().min(-90).max(90).required(),
  lng: Yup.number().min(-180).max(180).required(),
})

export const identifierValidationSchema = Yup.string().matches(/^[0-9]+$/)
