import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import MotionViewContainer from '../../components/MotionViewContainer'

const Image = styled('img')``
const NOT_FOUND_SRC = 'https://tokoku-web-assets.pages.dev/images/notfound.png'

function NotFound() {
  const { t } = useLocalization()
  const navigate = useNavigate()

  const onBackPressed = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <MotionViewContainer
      sx={{
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Image src={NOT_FOUND_SRC} sx={{ width: 160, height: 160 }} />
        <Typography variant="h4" sx={{ textAlign: 'center', mt: 1, mb: 1 }}>
          {t('views.not_found.subtext.title')}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center', mb: 2 }}>
          {t('views.not_found.subtext.subtitle')}
        </Typography>
        <Button onClick={onBackPressed} variant="outlined" color="primary">
          {t('button.label.go_back')}
        </Button>
      </Box>
    </MotionViewContainer>
  )
}

export default NotFound
