import { Navigate, useLocation } from 'react-router-dom'
import { useAuthUser } from '../../services/auth/query'
import AuthLoader from '../../components/AuthLoader'
import { RoutePath } from '../../routes/types'
import { useQueryNavigation } from '../../utils/routing/hooks'
import { PublicLayoutQuery } from './types'
import AnimatedOutlet from '../../components/AnimatedOutlet'
import { AnimatePresence, motion } from 'framer-motion'

function PublicLayout() {
  const { isLoading, user } = useAuthUser()
  const location = useLocation()
  const [query] = useQueryNavigation<PublicLayoutQuery>()

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={isLoading ? 'authloader' : location.pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.7 } }}
        exit={{ opacity: 0 }}
      >
        {isLoading ? (
          <AuthLoader />
        ) : (
          <>
            {user ? (
              <Navigate to={query.redirect || RoutePath.Home} replace />
            ) : (
              <AnimatedOutlet />
            )}
          </>
        )}
      </motion.div>
    </AnimatePresence>
  )
}

export default PublicLayout
