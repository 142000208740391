import { useState } from 'react'
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { AlternateEmail } from '@mui/icons-material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { PasswordInput } from '@tokoku-universe/react-core/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useQueryNavigation } from '../../utils/routing/hooks'
import { RoutePath } from '../../routes/types'
import { useLoginMutation } from '../../services/auth/query'
import TokokuBanner from '../../components/TokokuBanner'
import { LoginQueryParams } from './types'

const validationSchema = Yup.object({
  username: Yup.string().email().required(),
  password: Yup.string().min(8).max(50).required(),
})

function Login() {
  const { t } = useLocalization()
  const [error, setError] = useState('')
  const loginUser = useLoginMutation()
  const [query] = useQueryNavigation<LoginQueryParams>()
  const navigate = useNavigate()
  const { redirect } = query
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setError('')
        await loginUser({
          email: values.username,
          password: values.password,
        })
        redirect && navigate(redirect)
      } catch (e) {
        setError(String(e))
      }
    },
  })

  return (
    <Container
      maxWidth="xs"
      disableGutters
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'auto',
      }}
    >
      <TokokuBanner />
      <Card sx={{ width: '100%', mt: 2.5, p: 3.5 }}>
        <Typography variant="h6" fontWeight={500}>
          {t('views.login.heading.title')}
        </Typography>
        <Typography variant="body1" mb={4} color="textSecondary">
          {t('views.login.heading.subtitle')}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {t('views.login.error.login.generic.label')}
            </Alert>
          )}
          <Box width="100%">
            <TextField
              id="username"
              name="username"
              variant="outlined"
              fullWidth
              size="medium"
              type="email"
              sx={{ mb: 2 }}
              value={formik.values.username}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              label={t('views.login.text_field.username.label')}
              placeholder={t('views.login.text_field.username.placeholder')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ pr: 0.1 }}>
                    <AlternateEmail fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
            <PasswordInput
              id="password"
              name="password"
              variant="outlined"
              fullWidth
              size="medium"
              value={formik.values.password}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              label={t('views.login.text_field.password.label')}
              placeholder={t('views.login.text_field.password.placeholder')}
            />
            <Box
              mb={3}
              mt={0.75}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box />
              <Link
                color="primary"
                component={RouterLink}
                to={RoutePath.ForgotPassword}
                sx={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                  fontSize: ({ typography }) => typography.body2.fontSize,
                }}
              >
                {t('button.label.forgot_password')}
              </Link>
            </Box>
          </Box>
          <Button
            variant="contained"
            sx={{
              height: 56,
              fontSize: ({ typography }) => typography.body1.fontSize,
            }}
            disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
            type="submit"
            fullWidth
            disableElevation
          >
            {formik.isSubmitting && (
              <CircularProgress size={12} color="primary" sx={{ mr: 1 }} />
            )}
            {t('button.label.login')}
          </Button>
          <Typography mt={2} variant="body2" textAlign="center">
            {t('views.login.register.prefix')}
            <Link
              component={RouterLink}
              to={RoutePath.Register}
              sx={{ textDecoration: 'none', cursor: 'pointer', ml: 0.5 }}
            >
              {t('views.login.register.suffix')}
            </Link>
          </Typography>
        </form>
      </Card>
    </Container>
  )
}

export default Login
