export enum RoutePath {
  Base = '/',
  Login = '/login',
  Register = '/register',
  ForgotPassword = '/forgotpassword',
  Home = '/dashboard',
  Stores = '/stores',
  StoresCreate = '/stores/create',
  StoresEdit = '/stores/:id/edit',
  StoresCatalog = '/stores/:id/catalog',
  StoresTransactions = '/stores/:id/transactions',
  StoresAnalytics = '/stores/:id/analytics',
  StoresDevices = '/stores/:id/devices',
  StoresAttendance = '/stores/:id/attendance',
  StoresSettings = '/stores/:id/settings',
  Products = '/products',
  ProductsCreate = '/products/create',
  ProductsEdit = '/products/:id',
  Variants = '/variants',
  VariantsCreate = '/variants/create',
  VariantsEdit = '/variants/:id',
  Customers = '/customers',
  CustomersCreate = '/customers/create',
  CustomersEdit = '/customers/:phone',
  Campaigns = '/campaigns',
  CampaignsCreate = '/campaigns/create',
  CampaignsEdit = '/campaigns/:id',
  Loyalty = '/loyalty',
  LoyaltyCreate = '/loyalty/create',
  LoyaltyEdit = '/loyalty/:id',
  Reports = '/reports',
  Organization = '/organization',
  OrganizationSection = '/organization/:section',
  OrganizationPeopleCreate = '/organization/people/invite',
  OrganizationPeopleEdit = '/organization/people/:id',
  Settings = '/settings',
  SettingsSection = '/settings/:section',
}

export interface AsyncPageProps {
  page: string
}
