import { Box, Typography } from '@mui/material'
import { FastokoLogo } from '@tokoku-universe/react-core/components'

function TokokuBanner() {
  return (
    <Box display="flex" gap={0.5} position="relative" alignItems="center">
      <FastokoLogo width={48} height={48} />
      <Typography variant="h4" mb={1.5} color="text.primary">
        fastoko
      </Typography>
      <Typography
        color="primary.light"
        fontWeight="bold"
        variant="overline"
        position="absolute"
        right={-6}
        bottom={-8}
      >
        ADMIN
      </Typography>
    </Box>
  )
}

export default TokokuBanner
