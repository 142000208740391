import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Toolbar,
  Typography,
  styled,
} from '@mui/material'
import {
  NavLink,
  Link,
  useLocation,
  useParams,
  useNavigate,
  generatePath,
} from 'react-router-dom'
import { ArrowBack, LogoutOutlined } from '@mui/icons-material'
import { FastokoLogo } from '@tokoku-universe/react-core/components'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { useAuthUser, useLogoutMutation } from '../../../services/auth/query'
import { useStoreList } from '../../../services/stores/query'
import { createNavigationItems, storeNavigation } from '../../../navigation'
import { RoutePath } from '../../../routes/types'
import { useDeviceType } from '../../../utils/device/hooks'
import NavItem from './NavItem'
import { SIDEBAR_WIDTH } from './config'
import { SidebarProps } from './types'

const StyledNavLink = styled(NavLink)({ textDecoration: 'none' })

function Sidebar(props: SidebarProps) {
  const { open, toggleOpen, variant } = props
  const { currentLocale } = useLocalization()
  const { pathname } = useLocation()
  const { id } = useParams()
  const [isStoreSectionVisible, setIsStoreSectionVisible] = useState(false)
  const isDesktop = useDeviceType('desktop')
  const navigate = useNavigate()
  const navigation = useMemo(
    () => createNavigationItems(currentLocale),
    [currentLocale]
  )
  const { stores } = useStoreList()
  const { user } = useAuthUser()
  const logoutMutation = useLogoutMutation()
  const { t } = useLocalization()

  const firstLevelPosition = isDesktop ? SIDEBAR_WIDTH * -1 : 0
  const secondLevelPosition = isDesktop ? SIDEBAR_WIDTH : 0

  const toggleStoreSectionVisible = useCallback(() => {
    setIsStoreSectionVisible((open) => !open)
  }, [])

  useEffect(() => {
    const isStoreRoute =
      pathname !== RoutePath.Stores && pathname.startsWith(RoutePath.Stores)
    setIsStoreSectionVisible(isStoreRoute)
  }, [pathname])

  const onChangeSelectedStore = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!id) {
        return
      }

      navigate(pathname.replace(id, event.target.value))
    },
    [pathname, id, navigate]
  )

  return (
    <Drawer
      open={open}
      anchor="left"
      onClose={toggleOpen}
      variant={variant}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: SIDEBAR_WIDTH,
        },
      }}
    >
      <Toolbar
        sx={{
          paddingLeft: { xs: 3 },
          paddingRight: { xs: 1.5 },
          display: 'flex',
          gap: 1.15,
          flexDirection: 'row',
          alignItems: 'center',
          '& .MuiToolbar-root': {
            borderBottomColor: 'white',
            borderBottomWidth: 1,
          },
          textDecoration: 'none',
          color: ({ palette }) => palette.text.primary,
        }}
        component={Link}
        to={RoutePath.Base}
        onClick={toggleOpen}
      >
        <FastokoLogo width={28} height={28} />
        <Typography variant="h5" mt={0.4}>
          fastoko
          <Typography
            variant="overline"
            color="primary.main"
            ml={0.9}
            fontWeight="bold"
          >
            admin
          </Typography>
        </Typography>
      </Toolbar>
      <Divider />
      <AnimatePresence mode="wait">
        {isStoreSectionVisible && id ? (
          <Stack
            key="store"
            height="100%"
            pt={2}
            sx={{ overflowY: 'auto' }}
            component={motion.div}
            initial={{ opacity: 0, x: secondLevelPosition }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: secondLevelPosition }}
          >
            <Button
              variant="text"
              onClick={toggleStoreSectionVisible}
              sx={{ width: 'min-content', ml: 1.5 }}
              startIcon={<ArrowBack />}
            >
              {t('button.label.back')}
            </Button>
            <Box mx={2} mb={3} mt={1}>
              <Typography
                variant="overline"
                fontWeight="bold"
                letterSpacing={2}
                color="text.disabled"
              >
                {t('drawer.store_section.title.select_store')}
              </Typography>
              <TextField
                select
                value={id}
                onChange={onChangeSelectedStore}
                fullWidth
                size="small"
              >
                {stores?.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            {storeNavigation.map(({ key, url, external, IconComponent }) => (
              <StyledNavLink
                key={key}
                onClick={toggleOpen}
                to={generatePath(url, { id })}
                target={external ? '_blank' : undefined}
              >
                {({ isActive }) => (
                  <NavItem
                    titleKey={key}
                    active={isActive}
                    IconComponent={IconComponent}
                    external={external}
                  />
                )}
              </StyledNavLink>
            ))}
          </Stack>
        ) : (
          <Stack
            key="main"
            component={motion.div}
            height="100%"
            pt={2}
            sx={{ overflowY: 'auto' }}
            initial={{ opacity: 0, x: firstLevelPosition }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: firstLevelPosition }}
          >
            {navigation.map(({ key, items }, idx) => (
              <Fragment key={key}>
                <Typography
                  variant="overline"
                  fontWeight="bold"
                  letterSpacing={2}
                  color="text.disabled"
                  ml={3}
                  mt={idx !== 0 ? 3 : 0}
                >
                  {t(`drawer.section.title.${key}`)}
                </Typography>
                {items.map(({ key, url, external, IconComponent }) => (
                  <StyledNavLink
                    key={key}
                    onClick={toggleOpen}
                    to={url}
                    target={external ? '_blank' : undefined}
                  >
                    {({ isActive }) => (
                      <NavItem
                        titleKey={key}
                        active={isActive}
                        IconComponent={IconComponent}
                        external={external}
                      />
                    )}
                  </StyledNavLink>
                ))}
              </Fragment>
            ))}
          </Stack>
        )}
      </AnimatePresence>
      {user && (
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          p={3}
          pr={1.5}
          sx={{
            borderTop: ({ palette }) => `1px solid ${palette.divider}`,
          }}
          title={user?.email}
        >
          <Avatar
            sx={{ width: 40, height: 40 }}
            src="https://www.odysseyhouse.com.au/wordpress/wp-content/uploads/2018/02/cute-kitten-SQUARE@.jpg"
          />
          <Stack gap={1} flex={1}>
            <Typography variant="subtitle2" lineHeight={1}>
              {[user.firstName, user.lastName].filter(Boolean).join(' ')}
            </Typography>
            <Typography
              variant="caption"
              textTransform="uppercase"
              color="primary.main"
              fontWeight="bold"
              lineHeight={1}
            >
              {user.org?.name}
            </Typography>
          </Stack>
          <IconButton
            title={t('drawer.item.title.logout')}
            onClick={() => logoutMutation()}
          >
            <LogoutOutlined fontSize="small" />
          </IconButton>
        </Stack>
      )}
    </Drawer>
  )
}

export default Sidebar
