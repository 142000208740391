import { StoreChannel } from '../../config/types'
import apiClient from '../api'
import {
  ApiStore,
  ApiCreateStoreDto,
  ApiUpdateStoreDto,
  ApiStoreChannelCatalog,
  ApiStoreChannel,
  ApiCreateStoreChannelDto,
  ApiUpdateStoreChannelCatalog,
  ApiStoreChannelCatalogVersion,
  ApiTransaction,
  CreateTransactionDto,
  FindStoreTransactionsParams,
  FindStoreOptions,
  FindStoreQueryDto,
  ApiDevice,
  CreateStoreDeviceDto,
  UpdateTransactionDto,
} from './types'

export function findAllStores(opts: FindStoreOptions = {}) {
  const path = '/stores'
  const query: FindStoreQueryDto = {
    include:
      opts?.include && opts.include.length > 0
        ? opts.include.join(',')
        : undefined,
  }
  return apiClient.get<ApiStore[]>(path, query).then((res) => res.data)
}

export function findOneStore(storeId: string) {
  const path = `/stores/${storeId}`
  return apiClient.get<ApiStore>(path).then((res) => res.data)
}

export function createStore(payload: ApiCreateStoreDto) {
  const path = '/stores'
  return apiClient
    .post<ApiStore, ApiCreateStoreDto>(path, payload)
    .then((res) => res.data)
}

export function updateStore(storeId: string, payload: ApiUpdateStoreDto) {
  const path = `/stores/${storeId}`
  return apiClient
    .put<ApiStore, ApiUpdateStoreDto>(path, payload)
    .then((res) => res.data)
}

export function findStoreChannelCatalog(
  storeId: string,
  channel: StoreChannel
) {
  const path = `/stores/${storeId}/channels/${channel}/catalog`
  return apiClient.get<ApiStoreChannelCatalog>(path).then((res) => res.data)
}

export function findStoreChannelCatalogVersion(
  storeId: string,
  channel: StoreChannel,
  version: number | 'latest'
) {
  const path = `/stores/${storeId}/channels/${channel}/catalog/versions/${version}`
  return apiClient.get<ApiStoreChannelCatalog>(path).then((res) => res.data)
}

export function findStoreChannels(storeId: string) {
  const path = `/stores/${storeId}/channels`
  return apiClient.get<ApiStoreChannel[]>(path).then((res) => res.data)
}

export function createStoreChannel(storeId: string, channel: StoreChannel) {
  const path = `/stores/${storeId}/channels`
  return apiClient
    .post<ApiStoreChannel, ApiCreateStoreChannelDto>(path, {
      type: channel,
    })
    .then((res) => res.data)
}

export function updateStoreChannelCatalog(
  storeId: string,
  channel: StoreChannel,
  catalog: ApiUpdateStoreChannelCatalog
) {
  const path = `/stores/${storeId}/channels/${channel}/catalog`
  return apiClient
    .put<ApiStoreChannelCatalog, ApiUpdateStoreChannelCatalog>(path, catalog)
    .then((res) => res.data)
}

export function publishChannelCatalog(storeId: string, channel: StoreChannel) {
  const path = `/stores/${storeId}/channels/${channel}/catalog/publish`
  return apiClient
    .post<ApiStoreChannelCatalogVersion, object>(path)
    .then((res) => res.data)
}

export function findStoreTransactions(
  storeId: string,
  params?: FindStoreTransactionsParams
) {
  const path = `/stores/${storeId}/transactions`
  return apiClient
    .get<ApiTransaction[], FindStoreTransactionsParams>(path, params)
    .then((res) => res.data)
}

export function createStoreTransaction(
  storeId: string,
  transaction: CreateTransactionDto
) {
  const path = `/stores/${storeId}/transactions`
  return apiClient
    .post<ApiTransaction, CreateTransactionDto>(path, transaction)
    .then((res) => res.data)
}

export function updateStoreTransaction(
  storeId: string,
  transactionId: string,
  transaction: UpdateTransactionDto
) {
  const path = `/stores/${storeId}/transactions/${transactionId}`
  return apiClient
    .put<ApiTransaction, UpdateTransactionDto>(path, transaction)
    .then((res) => res.data)
}

export function findAllStoreDevices(storeId: string) {
  const path = `/stores/${storeId}/devices`
  return apiClient.get<ApiDevice[]>(path).then((res) => res.data)
}

export function createStoreDevice(
  storeId: string,
  params: CreateStoreDeviceDto
) {
  const path = `/stores/${storeId}/devices`
  return apiClient
    .post<ApiDevice, CreateStoreDeviceDto>(path, params)
    .then((res) => res.data)
}

export function deleteStoreDevice(storeId: string, deviceId: string) {
  const path = `/stores/${storeId}/devices/${deviceId}`
  return apiClient.delete(path).then((res) => res.data)
}
