import {
  SettingsOutlined,
  StoreOutlined,
  CampaignOutlined,
  PeopleOutlined,
  LoyaltyOutlined,
  LeaderboardOutlined,
  FastfoodOutlined,
  AccountTreeOutlined,
  FeedbackOutlined,
  ContactPageOutlined,
  Storefront,
  ListAltOutlined,
  MonetizationOnOutlined,
  AnalyticsOutlined,
  Devices,
  AccessTime,
} from '@mui/icons-material'
import { SupportedLocale } from '@tokoku-universe/react-core/localization'
import { RoutePath } from '../routes/types'
import { NavigationItem, NavigationSection } from './types'

const feedbackLink: Record<SupportedLocale, string> = {
  [SupportedLocale.English]:
    'https://g5bq4d9xlej.sg.larksuite.com/share/base/form/shrlg6Vd4ZmYoCLVUoQxvDthbOb',
  [SupportedLocale.Indonesian]:
    'https://g5bq4d9xlej.sg.larksuite.com/share/base/form/shrlgVSqpqVq63McrqxdBVMMkqh',
}

export function createNavigationItems(
  locale: SupportedLocale
): NavigationSection[] {
  return [
    {
      key: 'main',
      items: [
        {
          key: 'home',
          url: RoutePath.Home,
          IconComponent: LeaderboardOutlined,
          external: false,
        },
        {
          key: 'organization',
          url: RoutePath.Organization,
          IconComponent: AccountTreeOutlined,
          external: false,
        },
        {
          key: 'stores',
          url: RoutePath.Stores,
          IconComponent: StoreOutlined,
          external: false,
        },
        {
          key: 'products',
          url: RoutePath.Products,
          IconComponent: FastfoodOutlined,
          external: false,
        },
      ],
    },
    {
      key: 'engagement',
      items: [
        {
          key: 'campaigns',
          url: RoutePath.Campaigns,
          IconComponent: CampaignOutlined,
          external: false,
        },
        {
          key: 'loyalty',
          url: RoutePath.Loyalty,
          IconComponent: LoyaltyOutlined,
          external: false,
        },
        {
          key: 'customers',
          url: RoutePath.Customers,
          IconComponent: PeopleOutlined,
          external: false,
        },
      ],
    },
    {
      key: 'others',
      items: [
        {
          key: 'feedback',
          url: feedbackLink[locale],
          IconComponent: FeedbackOutlined,
          external: true,
        },
        {
          key: 'contact_us',
          url: 'mailto:contact@fastoko.com',
          IconComponent: ContactPageOutlined,
          external: true,
        },
        {
          key: 'settings',
          url: RoutePath.Settings,
          IconComponent: SettingsOutlined,
          external: false,
        },
      ],
    },
  ]
}

export const storeNavigation: NavigationItem[] = [
  {
    key: 'edit',
    url: RoutePath.StoresEdit,
    IconComponent: Storefront,
    external: false,
  },
  {
    key: 'catalog',
    url: RoutePath.StoresCatalog,
    IconComponent: ListAltOutlined,
    external: false,
  },
  {
    key: 'transactions',
    url: RoutePath.StoresTransactions,
    IconComponent: MonetizationOnOutlined,
    external: false,
  },
  {
    key: 'analytics',
    url: RoutePath.StoresAnalytics,
    IconComponent: AnalyticsOutlined,
    external: false,
  },
  {
    key: 'devices',
    url: RoutePath.StoresDevices,
    IconComponent: Devices,
    external: false,
  },
  {
    key: 'attendance',
    url: RoutePath.StoresAttendance,
    IconComponent: AccessTime,
    external: false,
  },
  {
    key: 'settings',
    url: RoutePath.StoresSettings,
    IconComponent: SettingsOutlined,
    external: false,
  },
]
