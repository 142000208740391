import { SvgIconComponent } from '@mui/icons-material'
import { ComponentClass, FunctionComponent } from 'react'
import { RoutePath } from '../routes/types'

export interface RouteConfig {
  key: string
  path?: string
  Component: FunctionComponent | ComponentClass
  exact?: boolean
}

export interface NavigationConfig {
  key: Navigation
  path: RoutePath
  IconComponent?: SvgIconComponent
  children?: NavigationConfig[]
}

export enum StorageKey {
  JwtAuthToken = 'JWT_AUTH_TOKEN',
}

export type ThemePreference = 'dark' | 'light' | null

export enum StoreChannel {
  PointOfSale = 'POS',
  GoFood = 'GOFOOD',
  GrabFood = 'GRABFOOD',
}

export enum StoreIndustry {
  Beauty = 'beauty',
  Clothing = 'clothing',
  Electronics = 'electronics',
  Furniture = 'furniture',
  Handcrafts = 'handcrafts',
  Jewelry = 'jewelry',
  Painting = 'painting',
  Photography = 'photography',
  Restaurants = 'restaurants',
  Groceries = 'groceries',
  FoodAndBeverages = 'food_and_beverages',
  Sports = 'sports',
  Toys = 'toys',
  Services = 'services',
  VirtualServices = 'virtual_services',
  Others = 'others',
}

export enum StoreCountry {
  Indonesia = 'ID',
  Singapore = 'SG',
}

export enum StoreCurrency {
  IndonesianRupiah = 'IDR',
  SingaporeDollar = 'SGD',
}

export enum StoreTimezone {
  PlusSeven = '+07:00',
  PlusEight = '+08:00',
  PlusNine = '+09:00',
}

export enum ProductStatus {
  Active = 'active',
  Draft = 'draft',
}

export enum Navigation {
  Home = 'home',
  Stores = 'stores',
  Settings = 'settings',
  Products = 'products',
  Customers = 'customers',
  Inventory = 'inventory',
  Campaigns = 'campaigns',
  Organization = 'organization',
  Transactions = 'transactions',
  Reports = 'reports',
}

export enum StoreOrderType {
  DineIn = 'DINE_IN',
  Takeaway = 'TAKEAWAY',
  Delivery = 'DELIVERY',
}

export enum StoreOrderStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Ready = 'READY',
  Delivering = 'DELIVERING',
  Complete = 'COMPLETE',
  Cancelled = 'CANCELLED',
  Refunded = 'REFUNDED',
  Paid = 'PAID',
}

export enum TransactionType {
  Revenue = 'REVENUE',
  Expense = 'EXPENSE',
}

export enum TransactionCategory {
  Order = 'ORDER',
  Rent = 'RENT',
  Utilities = 'UTILITIES',
  Salary = 'SALARY',
  Inventory = 'INVENTORY',
  Assets = 'ASSETS',
  Research = 'RESEARCH',
  Marketing = 'MARKETING',
  FranchiseFee = 'FRANCHISE_FEE',
  Others = 'OTHERS',
  None = 'NONE',
}

export enum CampaignType {
  Discount = 'DISCOUNT',
}

export enum CampaignStatus {
  Active = 'active',
  Inactive = 'inactive',
  Expired = 'expired',
}

export enum LoyaltyProgramStatus {
  Active = 'active',
  Inactive = 'inactive',
}
