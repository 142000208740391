import { useState } from 'react'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { PhoneInput } from '@tokoku-universe/react-core/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import TokokuBanner from '../../components/TokokuBanner'
import { register } from '../../services/auth'
import { RoutePath } from '../../routes/types'
import { phoneNumberValidationSchema } from '../../utils/validation'

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
  firstName: Yup.string().min(3).required(),
  lastName: Yup.string().min(3).required(),
  organization: Yup.string().min(1).required(),
  mobile: phoneNumberValidationSchema.required(),
  hasAgreed: Yup.boolean().isTrue().required(),
})

function Register() {
  const { t } = useLocalization()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      organization: '',
      mobile: '',
      hasAgreed: false,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      if (success) {
        return navigate(RoutePath.Login)
      }

      const { email, firstName, lastName, mobile, organization } = values
      try {
        setLoading(true)
        setError(false)
        setSuccess(false)
        await register({
          email,
          firstName,
          lastName,
          phoneNumber: mobile,
          org: organization,
        })
        setSuccess(true)
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        py: 4,
      }}
    >
      <TokokuBanner />
      <Card sx={{ width: '100%', mt: 2.5, p: 3.5 }}>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h5" mb={3}>
            {t('views.register.title')}
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {t('views.register.generic_error')}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 3 }}>
              {t('views.register.success')}
            </Alert>
          )}
          {!success && (
            <>
              <Typography mb={0.5}>
                {t('views.register.first_name.label')}
              </Typography>
              <TextField
                id="firstName"
                name="firstName"
                value={formik.values.firstName}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                variant="outlined"
                autoComplete="given-name"
                error={
                  formik.values.firstName
                    ? Boolean(formik.errors.firstName)
                    : false
                }
                helperText={
                  formik.values.firstName &&
                  formik.errors.firstName &&
                  t('views.register.first_name.helper')
                }
                type="text"
                size="small"
                fullWidth
              />

              <Typography mb={0.5} mt={2}>
                {t('views.register.last_name.label')}
              </Typography>
              <TextField
                id="lastName"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                variant="outlined"
                autoComplete="family-name"
                error={
                  formik.values.lastName
                    ? Boolean(formik.errors.lastName)
                    : false
                }
                helperText={
                  formik.values.lastName &&
                  formik.errors.lastName &&
                  t('views.register.last_name.helper')
                }
                type="text"
                size="small"
                disabled={formik.isSubmitting}
                fullWidth
              />

              <Typography mb={0.5} mt={2}>
                {t('views.register.email_address.label')}
              </Typography>
              <TextField
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                variant="outlined"
                error={
                  formik.values.email ? Boolean(formik.errors.email) : false
                }
                helperText={
                  formik.values.email &&
                  formik.errors.email &&
                  t('views.register.email_address.helper')
                }
                type="email"
                size="small"
                disabled={formik.isSubmitting}
                fullWidth
              />
              <Typography mb={0.5} mt={2}>
                {t('views.register.phone_number.label')}
              </Typography>
              <PhoneInput
                id="mobile"
                name="mobile"
                value={formik.values.mobile}
                onChangeValue={(value) => formik.setFieldValue('mobile', value)}
                onClear={() => formik.setFieldValue('mobile', '')}
                error={
                  formik.values.mobile ? Boolean(formik.errors.mobile) : false
                }
                helperText={
                  formik.values.mobile &&
                  formik.errors.mobile &&
                  t('views.register.phone_number.helper')
                }
                variant="outlined"
                size="small"
                disabled={formik.isSubmitting}
                fullWidth
              />
              <Typography mb={0.5} mt={2}>
                {t('views.register.organization.label')}
              </Typography>
              <TextField
                id="organization"
                name="organization"
                value={formik.values.organization}
                onChange={formik.handleChange}
                autoComplete="organization"
                error={
                  formik.values.organization
                    ? Boolean(formik.errors.organization)
                    : false
                }
                helperText={
                  formik.values.organization &&
                  formik.errors.organization &&
                  t('views.register.organization.helper')
                }
                variant="outlined"
                size="small"
                type="text"
                disabled={formik.isSubmitting}
                fullWidth
              />
              <FormControlLabel
                sx={{ mt: 1 }}
                onChange={() =>
                  formik.setFieldValue('hasAgreed', !formik.values.hasAgreed)
                }
                disabled={formik.isSubmitting}
                label={
                  <Typography variant="body2">
                    {t('views.register.terms.prefix')}
                    <Link
                      component={RouterLink}
                      to={RoutePath.Login}
                      sx={{ textDecoration: 'none', ml: 0.5 }}
                    >
                      {t('views.register.terms.suffix')}
                    </Link>
                  </Typography>
                }
                control={
                  <Checkbox checked={formik.values.hasAgreed} size="small" />
                }
              />
            </>
          )}
          <Button
            fullWidth
            variant="contained"
            type="submit"
            size="large"
            disabled={
              !success
                ? !(formik.isValid && formik.dirty) || formik.isSubmitting
                : false
            }
            disableElevation
            sx={{ mt: 1, height: 56 }}
          >
            {loading && (
              <CircularProgress size={14} sx={{ mr: 1 }} color="primary" />
            )}
            {t(
              success
                ? 'button.label.back_to_login'
                : 'button.label.create_an_account'
            )}
          </Button>
          {!success && (
            <Typography mt={2} variant="body2" textAlign="center">
              {t('views.register.login.prefix')}
              <Link
                component={RouterLink}
                to={RoutePath.Login}
                sx={{ textDecoration: 'none', cursor: 'pointer', ml: 0.5 }}
              >
                {t('views.register.login.suffix')}
              </Link>
            </Typography>
          )}
        </form>
      </Card>
    </Container>
  )
}

export default Register
