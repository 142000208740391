import { useState } from 'react'
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  InputAdornment,
  TextField,
  Typography,
  Link,
} from '@mui/material'
import { AlternateEmail } from '@mui/icons-material'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { forgotPassword } from '../../services/auth'
import { RoutePath } from '../../routes/types'
import TokokuBanner from '../../components/TokokuBanner'

const validationSchema = Yup.object({
  username: Yup.string().email().required(),
})

function ForgotPassword() {
  const { t } = useLocalization()
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const formik = useFormik({
    initialValues: {
      username: '',
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      if (success) {
        return navigate(RoutePath.Login)
      }

      try {
        setSuccess(false)
        setError('')
        await forgotPassword({ email: values.username })
        setSuccess(true)
      } catch (e) {
        setError(String(e))
      }
    },
  })

  return (
    <Container
      maxWidth="xs"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'auto',
      }}
    >
      <TokokuBanner />
      <Card sx={{ width: '100%', mt: 2.5, p: 3.5 }}>
        <Typography variant="h6" fontWeight={500}>
          {t('views.forgot_password.heading.title')}
        </Typography>
        <Typography variant="body1" mb={4} color="textSecondary">
          {t('views.forgot_password.heading.subtitle')}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          {success && (
            <Alert severity="success" sx={{ mb: 3 }}>
              {t('views.forgot_password.alert.success.generic.label')}
            </Alert>
          )}
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {t('views.forgot_password.alert.error.generic.label')}
            </Alert>
          )}
          <Box width="100%">
            <TextField
              id="username"
              name="username"
              variant="outlined"
              fullWidth
              size="medium"
              type="email"
              sx={{ mb: 2 }}
              value={formik.values.username}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting || success}
              label={t('views.login.text_field.username.label')}
              placeholder={t('views.login.text_field.username.placeholder')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ pr: 0.1 }}>
                    <AlternateEmail fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button
            variant={success ? 'outlined' : 'contained'}
            sx={{
              height: 56,
              fontSize: ({ typography }) => typography.body1.fontSize,
            }}
            disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
            type="submit"
            fullWidth
            disableElevation
          >
            {formik.isSubmitting && (
              <CircularProgress size={12} color="primary" sx={{ mr: 1 }} />
            )}
            {t(
              success
                ? 'button.label.back_to_login'
                : 'button.label.reset_password'
            )}
          </Button>
          {!success && (
            <Box mt={2} display="flex" justifyContent="center">
              <Link
                component={RouterLink}
                to={RoutePath.Login}
                sx={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                  fontSize: ({ typography }) => typography.body2.fontSize,
                }}
              >
                {t('button.label.back_to_login')}
              </Link>
            </Box>
          )}
        </form>
      </Card>
    </Container>
  )
}

export default ForgotPassword
