import { styled, BoxProps, Box, Typography, alpha } from '@mui/material'
import { Launch } from '@mui/icons-material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { NAV_ITEM_MIN_HEIGHT } from '../config'
import { NavItemProps, StyledNavItemProps } from './types'

export const NavigationBox = styled(Box)<BoxProps & StyledNavItemProps>(
  ({ selected, theme }) => ({
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 1,
    minHeight: NAV_ITEM_MIN_HEIGHT,
    color: theme.palette.text.secondary,
    ':hover': {
      color: selected ? theme.palette.primary.main : theme.palette.text.primary,
      backgroundColor: alpha(
        theme.palette.primary.main,
        selected ? 0.08 : 0.05
      ),
    },
    ...(selected && {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      color: theme.palette.primary.light,
    }),
  })
)

function NavItem(props: NavItemProps) {
  const { titleKey, active, IconComponent, external = false } = props
  const { t } = useLocalization()
  const title = t(`drawer.item.title.${titleKey}`)

  return (
    <NavigationBox
      selected={active}
      pl={2}
      pr={0.5}
      mx={1}
      my={0.1}
      sx={{
        borderRadius: 2,
      }}
      title={title}
    >
      {IconComponent && (
        <IconComponent
          sx={{
            color: active ? 'primary.light' : 'action',
            mr: 1.2,
            width: '0.9em',
            height: '0.9em',
          }}
        />
      )}
      <Typography
        component="span"
        flex={1}
        variant="subtitle2"
        justifyContent="flex-start"
        display="flex"
      >
        {title}
      </Typography>
      {external && (
        <Launch
          sx={{
            color: ({ palette }) => palette.action.active,
            width: '0.7em',
            height: '0.7em',
            mr: 1,
          }}
        />
      )}
    </NavigationBox>
  )
}

export default NavItem
