import { useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import queryClient from '../../config/query'
import { StoreChannel } from '../../config/types'
import {
  createStore,
  createStoreChannel,
  createStoreDevice,
  createStoreTransaction,
  deleteStoreDevice,
  findAllStoreDevices,
  findAllStores,
  findOneStore,
  findStoreChannelCatalog,
  findStoreChannelCatalogVersion,
  findStoreChannels,
  findStoreTransactions,
  publishChannelCatalog,
  updateStore,
  updateStoreChannelCatalog,
  updateStoreTransaction,
} from '.'
import {
  ApiStore,
  ApiCreateStoreDto,
  ApiUpdateStoreDto,
  ApiTransaction,
  CreateTransactionDto,
  FindStoreTransactionsParams,
  ApiStoreChannel,
  ApiStoreChannelCatalog,
  ApiUpdateStoreChannelCatalog,
  CreateStoreDeviceDto,
  UpdateTransactionDto,
} from './types'

const STORE_QUERY_KEY = 'stores'
const STORE_TRANSACTIONS_QUERY_KEY = 'stores.transactions'
const STORE_CHANNELS_QUERY_KEY = 'stores.channels'
const STORE_CATALOG_QUERY_KEY = 'stores.catalog'
const STORE_DEVICES_QUERY_KEY = 'stores.devices'
const STORE_CACHE_TIME = 5 * 60 * 1000 // 5 minutes
const STORE_TRANSACTIONS_CACHE_TIME = Infinity

export function useStoreList(staleTime = STORE_CACHE_TIME) {
  const { isLoading, data: stores } = useQuery({
    queryKey: [STORE_QUERY_KEY],
    queryFn: () =>
      findAllStores({
        include: ['channels'],
      }),
    staleTime,
  })

  return { isLoading, stores }
}

export function useStoreListById(staleTime = STORE_CACHE_TIME) {
  const { stores } = useStoreList(staleTime)

  const storeListById = useMemo<Record<string, ApiStore>>(() => {
    if (!stores) {
      return {}
    }

    return stores.reduce<Record<string, ApiStore>>((prev, curr) => {
      prev[curr.id] = curr
      return prev
    }, {})
  }, [stores])

  return storeListById
}

export function useStoreChannelList(storeId?: string) {
  const { isLoading, data: channels } = useQuery<ApiStoreChannel[]>({
    queryKey: [STORE_CHANNELS_QUERY_KEY, storeId],
    queryFn: () => findStoreChannels(storeId || ''),
    enabled: Boolean(storeId),
    staleTime: Infinity,
  })

  return { isLoading, channels }
}

export function useStoreById(storeId?: string) {
  const {
    isFetching,
    isLoading,
    data: store,
  } = useQuery<ApiStore>({
    queryKey: [STORE_QUERY_KEY, storeId],
    queryFn: () => findOneStore(storeId || ''),
    enabled: Boolean(storeId),
    staleTime: Infinity,
  })

  return { store, isFetching, isLoading }
}

export function useStoreTransactions(
  storeId?: string,
  params?: FindStoreTransactionsParams
) {
  const { isLoading, data: transactions } = useQuery<ApiTransaction[]>({
    queryKey: [STORE_TRANSACTIONS_QUERY_KEY, storeId, params],
    queryFn: () => findStoreTransactions(storeId || '', params),
    enabled: Boolean(storeId),
    staleTime: STORE_TRANSACTIONS_CACHE_TIME,
  })

  return { isLoading, transactions }
}

export function useStoreTransactionById(
  storeId?: string,
  transactionId?: string
) {
  const { isLoading, transactions } = useStoreTransactions(storeId)
  return {
    isLoading,
    transaction: transactions?.find(({ id }) => id === transactionId),
  }
}

function catalogRetryHandler(failureCount: number, error: AxiosError) {
  if (error?.response?.status === 404) {
    // do not retry when catalog returns 404, this can
    // mean that the catalog just hasn't been created yet
    return false
  }

  return failureCount < 6
}

export function useStoreChannelDraftCatalog(
  storeId?: string,
  channel?: StoreChannel
) {
  const { isLoading, data: catalog } = useQuery<
    ApiStoreChannelCatalog,
    AxiosError
  >({
    queryKey: [STORE_CATALOG_QUERY_KEY, 'draft', storeId, channel],
    queryFn: () =>
      findStoreChannelCatalog(
        storeId || '',
        channel || StoreChannel.PointOfSale
      ),
    enabled: Boolean(storeId && channel),
    staleTime: STORE_TRANSACTIONS_CACHE_TIME,
    retry: catalogRetryHandler,
  })

  return { isLoading, catalog }
}

export function useStoreChannelLatestCatalog(
  storeId?: string,
  channel?: StoreChannel
) {
  const { isLoading, data: catalog } = useQuery<
    ApiStoreChannelCatalog,
    AxiosError
  >({
    queryKey: [STORE_CATALOG_QUERY_KEY, 'latest', storeId, channel],
    queryFn: () =>
      findStoreChannelCatalogVersion(
        storeId || '',
        channel || StoreChannel.PointOfSale,
        'latest'
      ),
    enabled: Boolean(storeId && channel),
    staleTime: Infinity,
    retry: catalogRetryHandler,
  })

  return { isLoading, catalog }
}

export function useSaveStoreChannelDraftCatalogMutation(
  storeId: string,
  channel: StoreChannel
) {
  const mutation = useMutation({
    mutationFn: (catalog: ApiUpdateStoreChannelCatalog) =>
      updateStoreChannelCatalog(storeId, channel, catalog),
    onSuccess: () => invalidateStoreChannelDraftCatalog(storeId, channel),
  })

  return mutation.mutateAsync
}

export function usePublishStoreChannelCatalog(
  storeId: string,
  channel: StoreChannel
) {
  const mutation = useMutation({
    mutationFn: () => publishChannelCatalog(storeId, channel),
    onSuccess: () => invalidateStoreChannelLatestCatalog(storeId, channel),
  })

  return mutation.mutateAsync
}

export function useCreateStoreTransactionMutation(storeId?: string) {
  const mutation = useMutation({
    mutationFn: (transaction: CreateTransactionDto) =>
      createStoreTransaction(storeId || '', transaction),
    onSuccess: () => invalidateStoreTransactionList(storeId),
  })

  return mutation.mutateAsync
}

export function useUpdateStoreTransactionMutation(
  storeId?: string,
  transactionId?: string
) {
  const mutation = useMutation({
    mutationFn: (transaction: UpdateTransactionDto) =>
      updateStoreTransaction(storeId || '', transactionId || '', transaction),
    onSuccess: () => invalidateStoreTransactionList(storeId),
  })

  return mutation.mutateAsync
}

export function useCreateStoreMutation() {
  const mutation = useMutation({
    mutationFn: (store: ApiCreateStoreDto) => createStore(store),
    onSuccess: invalidateStoreList,
  })

  return mutation.mutateAsync
}

export function useUpdateStoreMutation(storeId?: string) {
  const mutation = useMutation({
    mutationFn: (store: ApiUpdateStoreDto) =>
      storeId ? updateStore(storeId, store) : Promise.resolve(null),
    onSuccess: invalidateStoreList,
  })

  return mutation.mutateAsync
}

export function useCreateStoreChannelMutation(storeId?: string) {
  const mutation = useMutation({
    mutationFn: (channel: StoreChannel) =>
      storeId ? createStoreChannel(storeId, channel) : Promise.resolve(null),
    onSuccess: invalidateStoreList,
  })

  return mutation.mutateAsync
}

export function useStoreDeviceList(storeId?: string) {
  const { isLoading, data: devices } = useQuery({
    queryKey: [STORE_DEVICES_QUERY_KEY, storeId],
    queryFn: () => findAllStoreDevices(storeId || ''),
    enabled: Boolean(storeId),
    staleTime: Infinity,
  })

  return { isLoading, devices }
}

export function useStoreDeviceById(storeId?: string, deviceId?: string) {
  const { isLoading, devices } = useStoreDeviceList(storeId)

  const device = useMemo(() => {
    if (!devices || !deviceId) {
      return undefined
    }

    return devices.find(({ id }) => id === deviceId)
  }, [devices, deviceId])

  return { isLoading, device }
}

export function useCreateStoreDeviceMutation(storeId?: string) {
  const mutation = useMutation({
    mutationFn: (device: CreateStoreDeviceDto) =>
      createStoreDevice(storeId || '', device),
    onSuccess: () => invalidateStoreDeviceList(storeId),
  })

  return mutation.mutateAsync
}

export function useDeleteStoreDeviceMutation(storeId?: string) {
  const mutation = useMutation({
    mutationFn: (deviceId: string | string[]) => {
      if (typeof deviceId === 'string') {
        return deleteStoreDevice(storeId || '', deviceId)
      } else {
        return Promise.all(
          deviceId.map((id) => deleteStoreDevice(storeId || '', id))
        )
      }
    },
    onSuccess: () => invalidateStoreDeviceList(storeId),
  })

  return mutation.mutateAsync
}

export function invalidateStoreList() {
  queryClient.invalidateQueries({ queryKey: [STORE_QUERY_KEY] })
  return queryClient.invalidateQueries({ queryKey: [STORE_CHANNELS_QUERY_KEY] })
}

export function invalidateStoreTransactionList(storeId?: string) {
  return queryClient.invalidateQueries({
    queryKey: [STORE_TRANSACTIONS_QUERY_KEY, storeId],
  })
}

export function invalidateStoreChannelDraftCatalog(
  storeId: string,
  channel: StoreChannel
) {
  return queryClient.invalidateQueries({
    queryKey: [STORE_CATALOG_QUERY_KEY, 'draft', storeId, channel],
  })
}

export function invalidateStoreChannelLatestCatalog(
  storeId: string,
  channel: StoreChannel
) {
  return queryClient.invalidateQueries({
    queryKey: [STORE_CATALOG_QUERY_KEY, 'latest', storeId, channel],
  })
}

export function invalidateStoreDeviceList(storeId?: string) {
  return queryClient.invalidateQueries({
    queryKey: [STORE_DEVICES_QUERY_KEY, storeId],
  })
}
