import {
  alpha,
  AppBar,
  Divider,
  Toolbar,
  Box,
  Button,
  Stack,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { MenuRounded } from '@mui/icons-material'
import { FastokoLogo } from '@tokoku-universe/react-core/components'
import { useDeviceType } from '../../../utils/device/hooks'
import { SIDEBAR_WIDTH } from '../Sidebar/config'
import { RoutePath } from '../../../routes/types'
import { HeaderProps } from './types'

function Header(props: HeaderProps) {
  const { toggleDrawerOpen } = props
  const isMobile = useDeviceType('mobile')

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer - 1,
        boxShadow: 0,
        backdropFilter: 'blur(20px)',
        backgroundImage: 'none',
        backgroundColor: ({ palette }) =>
          alpha(palette.background.default, 0.6),
        width: { md: `calc(100% - ${SIDEBAR_WIDTH}px)` },
        ml: { md: `${SIDEBAR_WIDTH}px` },
      }}
    >
      <Toolbar sx={{ backgroundColor: 'transparent' }}>
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          {isMobile ? (
            <Stack direction="row" gap={2.5}>
              <Button
                onClick={toggleDrawerOpen}
                variant="outlined"
                sx={{ padding: '5px', minWidth: 0, borderRadius: 1.5 }}
              >
                <MenuRounded fontSize="small" />
              </Button>
              <Box
                component={Link}
                to={RoutePath.Base}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <FastokoLogo width={28} height={28} />
              </Box>
            </Stack>
          ) : (
            <Box />
          )}
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  )
}

export default Header
