import { Box, Typography } from '@mui/material'
import HashLoader from 'react-spinners/HashLoader'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import useTheming from '../../utils/theme/hooks'

function AuthLoader() {
  const { palette } = useTheming()
  const { t } = useLocalization()

  return (
    <Box
      sx={{ backgroundColor: ({ palette }) => palette.background.paper }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <HashLoader size={64} loading color={palette.primary.main} />
      <Typography mt={2} variant="body2" color="textSecondary">
        {t('views.loader.auth.label')}
      </Typography>
    </Box>
  )
}

export default AuthLoader
