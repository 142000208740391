import { Backdrop } from '@mui/material'
import { useIsMutating } from '@tanstack/react-query'
import ClockLoader from 'react-spinners/ClockLoader'
import useTheming from '../../utils/theme/hooks'
import { usePageLoadingState } from '../../services/ui/store'

function PageLoader() {
  const { palette } = useTheming()
  const isPageLoading = usePageLoadingState((state) => state.pageLoading)
  const isMutating = useIsMutating()
  const isLoading = isPageLoading || Boolean(isMutating)

  return (
    <Backdrop
      open={isLoading}
      sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
    >
      <ClockLoader size={64} loading color={palette.primary.main} />
    </Backdrop>
  )
}

export default PageLoader
