import { useMemo } from 'react'
import { Theme, useMediaQuery } from '@mui/material'
import useTheming from '../theme/hooks'
import { AppBarMinHeight } from './types'

export function useDeviceType(type: 'mobile' | 'desktop') {
  const mdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const isDeviceType = useMemo(() => {
    return type === 'mobile' ? mdDown : !mdDown
  }, [type, mdDown])
  return isDeviceType
}

export function useAppBarHeight() {
  const {
    mixins: { toolbar },
    breakpoints,
  } = useTheming()
  const toolbarDesktopQuery = breakpoints.up('sm')
  const toolbarLandscapeQuery = `${breakpoints.up(
    'xs'
  )} and (orientation: landscape)`
  const isDesktop = useMediaQuery(toolbarDesktopQuery)
  const isLandscape = useMediaQuery(toolbarLandscapeQuery)
  let currentToolbarMinHeight
  if (isDesktop) {
    currentToolbarMinHeight = toolbar[toolbarDesktopQuery]
  } else if (isLandscape) {
    currentToolbarMinHeight = toolbar[toolbarLandscapeQuery]
  } else {
    currentToolbarMinHeight = toolbar
  }
  return (currentToolbarMinHeight as AppBarMinHeight).minHeight
}
