import { AxiosInstance, AxiosResponse } from 'axios'
import { BaseHttpConfig, BaseHttpResponse } from './types'

export function createHttpClient(client: AxiosInstance) {
  return {
    get: <T, U = object>(path: string, params?: U) => {
      return client
        .get<BaseHttpResponse<T>>(path, {
          params,
        })
        .then((res) => res.data)
    },
    put: <T, D>(path: string, payload?: D) => {
      return client
        .put<BaseHttpResponse<T>>(path, payload)
        .then((res) => res.data)
    },
    post: <T, D>(path: string, payload?: D, config?: BaseHttpConfig) => {
      return client
        .post<BaseHttpResponse<T>, AxiosResponse<BaseHttpResponse<T>>, D>(
          path,
          payload,
          config
        )
        .then((res) => res.data)
    },
    patch: <T, D>(path: string, payload?: D) => {
      return client
        .patch<BaseHttpResponse<T>, AxiosResponse<BaseHttpResponse<T>>, D>(
          path,
          payload
        )
        .then((res) => res.data)
    },
    delete: <T>(path: string) => {
      return client.delete<BaseHttpResponse<T>>(path).then((res) => res.data)
    },
  }
}
