import { useQuery, useMutation } from '@tanstack/react-query'
import queryClient from '../../config/query'
import { ApiUser } from '../users/types'
import { authenticateUser, loginUser, logoutUser, updateProfile } from '.'
import { LoginDto } from './types'

const AUTH_USER_QUERY_KEY = 'authUser'

export function useAuthUser() {
  const { isLoading, data: user } = useQuery<ApiUser | null>({
    queryKey: [AUTH_USER_QUERY_KEY],
    queryFn: () => {
      return Promise.all([
        authenticateUser(),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]).then(([user]) => user)
    },
    staleTime: Infinity,
  })

  return { isLoading, user }
}

export function useLoginMutation() {
  const mutation = useMutation({
    mutationFn: async (params: LoginDto) => {
      await loginUser(params)
      const user = await authenticateUser()
      queryClient.setQueryData([AUTH_USER_QUERY_KEY], user)
    },
  })

  return mutation.mutateAsync
}

export function useLogoutMutation() {
  const mutation = useMutation({
    mutationFn: async () => {
      queryClient.setQueryData([AUTH_USER_QUERY_KEY], null)
      await logoutUser()
    },
  })

  return mutation.mutateAsync
}

export function useUpdateProfileMutation() {
  const mutation = useMutation({
    mutationFn: updateProfile,
    onSuccess: invalidateAuthUser,
  })

  return mutation.mutateAsync
}

export function invalidateAuthUser() {
  return queryClient.invalidateQueries({
    queryKey: [AUTH_USER_QUERY_KEY],
  })
}
