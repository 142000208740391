import { Box, Typography, Button } from '@mui/material'
import { motion } from 'framer-motion'
import { styled } from '@mui/material/styles'
import { useLocalization } from '@tokoku-universe/react-core/localization'

const Image = styled('img')``
const INTERNAL_ERROR_SRC =
  'https://tokoku-web-assets.pages.dev/images/internalerror.png'

function InternalError() {
  const { t } = useLocalization()

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Image src={INTERNAL_ERROR_SRC} sx={{ width: 160, height: 160 }} />
      <Typography variant="h4" sx={{ textAlign: 'center', mt: 1, mb: 1 }}>
        {t('views.internal_error.subtext.title')}
      </Typography>
      <Typography variant="body2" sx={{ textAlign: 'center', mb: 2 }}>
        {t('views.internal_error.subtext.subtitle')}
      </Typography>
      <Button href="/" variant="outlined" color="primary">
        {t('button.label.go_back')}
      </Button>
    </Box>
  )
}

export default InternalError
