import { useCallback, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Box, Stack, Toolbar } from '@mui/material'
import { useAuthUser } from '../../services/auth/query'
import { useLoginRedirectPath } from '../../utils/routing/hooks'
import { useDeviceType } from '../../utils/device/hooks'
import { SIDEBAR_WIDTH } from './Sidebar/config'
import Header from './Header'
import Sidebar from './Sidebar'

function MainLayout() {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const redirectPath = useLoginRedirectPath()
  const isDesktop = useDeviceType('desktop')
  const { user } = useAuthUser()

  const toggleDrawerOpen = useCallback(() => {
    setDrawerOpen((open) => !open)
  }, [])

  return user ? (
    <Stack width="100%" height="100vh" sx={{ whiteSpace: 'pre-line' }}>
      {!isDesktop && <Header toggleDrawerOpen={toggleDrawerOpen} />}
      <Sidebar
        open={drawerOpen}
        toggleOpen={toggleDrawerOpen}
        variant={isDesktop ? 'permanent' : 'temporary'}
      />
      <Box
        component="main"
        height="100vh"
        width={isDesktop ? `calc(100% - ${SIDEBAR_WIDTH}px)` : '100%'}
        display="flex"
        flexDirection="column"
        sx={{ marginLeft: 'auto' }}
      >
        {!isDesktop && <Toolbar />}
        <Outlet />
      </Box>
    </Stack>
  ) : (
    <Navigate to={redirectPath} replace />
  )
}

export default MainLayout
