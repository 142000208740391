import { MobilePhoneLocale } from 'validator/es/lib/isMobilePhone'
import { StoreCountry, StoreCurrency, StoreTimezone } from './types'

export const DRAWER_WIDTH = 280
export const MAX_VARIANT_CAP = 10

export const storeCurrenciesByCountry: Record<StoreCountry, StoreCurrency[]> = {
  [StoreCountry.Indonesia]: [StoreCurrency.IndonesianRupiah],
  [StoreCountry.Singapore]: [StoreCurrency.SingaporeDollar],
}

export const mobilePhoneLocaleByCountry: Record<
  StoreCountry,
  MobilePhoneLocale
> = {
  [StoreCountry.Indonesia]: 'id-ID',
  [StoreCountry.Singapore]: 'en-SG',
}

export const storeTimezonesByCountry: Record<StoreCountry, StoreTimezone[]> = {
  [StoreCountry.Indonesia]: [
    StoreTimezone.PlusSeven,
    StoreTimezone.PlusEight,
    StoreTimezone.PlusNine,
  ],
  [StoreCountry.Singapore]: [StoreTimezone.PlusEight],
}

export const registerDeviceTokenURL = `${
  import.meta.env.VITE_POS_BASE_URL
}/device/register`

export const IS_HTTP_WITH_CREDENTIALS =
  import.meta.env.VITE_APP_ENV !== 'integration' &&
  import.meta.env.VITE_APP_ENV !== 'development' &&
  !import.meta.env.DEV
