import { Container } from '@mui/material'
import { motion } from 'framer-motion'
import { MotionViewContainerProps } from './types'

function MotionViewContainer(props: MotionViewContainerProps) {
  const { key, children, maxWidth, disableGutters, sx } = props

  return (
    <Container
      key={key}
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: { duration: 0.7, staggerChildren: 0.5 },
      }}
      exit={{ opacity: 0, y: 20 }}
      maxWidth={maxWidth}
      disableGutters={disableGutters}
      sx={sx}
    >
      {children}
    </Container>
  )
}

export default MotionViewContainer
